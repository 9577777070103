import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/iconfont/iconfont.css'
import './assets/iconfont2/iconfont.css'
import './assets/iconfont3/iconfont.css'
import './assets/iconfont4/iconfont.css'
import './assets/iconfont5/iconfont.css'
import './assets/iconfont6/iconfont.css'
import './components/HomeComponents/clicklove/clicklove'
import VueLazyload from 'vue-lazyload'
import axios from 'axios'
//从cdn引入
//import './plugins/iview.js'

axios.interceptors.request.use(config => {
  //console.log(config)
  config.headers['X-Access-Token'] = window.localStorage.getItem("token")
  //在最后必须return config
  return config
})

axios.interceptors.response.use(response => {
    const res = response.data
    if (res.code === 200) {
      return response
    }
    if (res.code === 503) {
      debugger
    }
    if (res.code === 201) {
      return response;
    }
    if (res.code === 401) {
      localStorage.clear()
      return response
    }
  }
);

Vue.prototype.$axios = axios

Vue.config.productionTip = false

// Vue.http.interceptors.push((request, next) => {
//   //request.credentials = true; // 接口每次请求会跨域携带cookie
//   //request.method= 'POST'; // 请求方式（get,post）
//   //request.headers.set('token','111') // 请求headers携带参数
//   console.log('拦截了')
//   next(function (response) {
//     return response;
//
//   });
// })

// 图片懒加载
Vue.use(VueLazyload, {
  loading: './assets/images/loading.png'
})
Vue.prototype.bus = new Vue()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
